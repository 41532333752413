import * as React from "react";
import {
	Body,
	Container,
	Icon,
	Left,
	ListItem,
	Right,
	Text,
	View,
} from "native-base";
import config from "../../utils/config";
import Axios from "axios";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList } from "react-native-gesture-handler";
import PreLoader from "../../components/PreLoader";
import HandleHTTPError from "../../utils/handleErrors";
import { Platform, StyleSheet } from "react-native";

var numeral = require("numeral");

export default function RequestListAutoScreen(props) {
	const { navigation } = props;
	const [working, setWorking] = React.useState(false);
	const [dataList, setDataList] = React.useState([]);

	React.useEffect(() => {
		setWorking(true);
		const uri = `${config.api.host}requisitions/auto`;
		Axios.get(uri, {
			headers: config.api.headers,
		})
			.then(({ data }) => {
				const currentYear = new Date().getFullYear();
				const filteredData = data.results.filter(
					(el) => el.capex.year === currentYear
				);
				setDataList(filteredData);
				setWorking(false);
			})
			.catch((error) => {
				if (Platform.OS === "web") {
					alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
				} else {
					HandleHTTPError(error, navigation);
				}
				setWorking(false);
			});
	}, []);

	const renderLastAprove = (element) => {
		if (element.rejected_by) {
			return <Text>No aprobada por: {element.rejected_by.full_name}</Text>;
		} else {
			if (element.approved_by) {
				return <Text>Aprobada por: {element.approved_by.full_name}</Text>;
			}
		}
		return null;
	};

	const renderNextAprove = (element) => {
		if (element.reviewer) {
			return <Text>Próximo revisor: {element.reviewer.full_name}</Text>;
		}
		return null;
	};

	const renderStatus = (status) => {
		if (status === 0) {
			return (
				<Text style={{ color: "#007bff", alignSelf: "flex-end" }}>
					En proceso
				</Text>
			);
		}
		if (status === 1) {
			return (
				<Text style={{ color: "#28a745", alignSelf: "flex-end" }}>
					Aprobada
				</Text>
			);
		}
		if (status === 2) {
			return (
				<Text style={{ color: "#dc3545", alignSelf: "flex-end" }}>
					Rechazada
				</Text>
			);
		}
		if (status === 3) {
			return (
				<Text style={{ color: "#dc3545", alignSelf: "flex-end" }}>
					Cancelada
				</Text>
			);
		}
	};

	const renderKind = (kind) => {
		if (kind === "capex") {
			return <Text style={{ color: "#007bff" }}>CAPEX</Text>;
		}
		if (kind === "sub") {
			return <Text style={{ color: "#6c757d" }}>Subdivisión</Text>;
		}
		if (kind === "change") {
			return <Text style={{ color: "#28a745" }}>Cambio</Text>;
		}
		return <Text style={{ color: "#dc3545" }}>AUTO</Text>;
	};

	// const renderListItem = (element) => {
	//     if (Platform.OS === 'web') {
	//         return (
	//             <ListItem button>
	//                 <Body>
	//                     <Text>{element.name} {renderKind(element.kind)}</Text>
	//                     {/* <Text>Solicitante: {element.requested_by?.full_name}</Text> */}
	//                     <Text>Descripción: {element.description}</Text>
	//                     <Text numberOfLines={2} ellipsizeMode='tail' >Detalles: {element.details}</Text>

	//                 </Body>
	//                 <View>

	//                     {renderStatus(element.status)}
	//                     {renderLastAprove(element)}
	//                 </View>
	//             </ListItem>
	//         )
	//     }

	//     return (
	//         <ListItem
	//         //  onPress={() => navigation.navigate('AproveCapexScreen', {
	//         //     capex: element
	//         // })}
	//         >
	//             <Body>
	//                 <Text>{element.name} {renderKind(element.kind)}</Text>
	//                 <Text>Descripción: {element.description}</Text>
	//                 <Text numberOfLines={2} ellipsizeMode='tail' >Detalles: {element.details}</Text>
	//                 {renderStatus(element.status)}
	//                 {renderLastAprove(element)}
	//             </Body>
	//             {/* <Right>
	//                 <Icon name='md-arrow-forward' />
	//             </Right> */}
	//         </ListItem>
	//     )
	// }

	const renderListItem = (element) => {
		// const route = getNextScreen(element.kind)
		if (Platform.OS === "web") {
			return (
				<ListItem
					button
					// onPress={() => navigation.navigate(route, {
					//   item: element.id
					// })}
				>
					<Body>
						<Text>
							{element.name} {renderKind(element.kind)}
						</Text>
						<Text>Solicitante: {element.requested_by?.full_name}</Text>
						<Text>Descripción: {element.description}</Text>
						{/* <Text>Objetivo: {element.objective}</Text> */}
					</Body>
					<View>
						<Text>
							Monto solicitado:
							<Text style={{ fontWeight: "bold", color: "green" }}>
								{numeral(element.qty_requested).format("000,000.00")}
							</Text>
						</Text>
						{renderStatus(element.status)}
						{renderLastAprove(element)}
						{renderNextAprove(element)}
					</View>
				</ListItem>
			);
		}
		return (
			<ListItem
				button
				//   onPress={() => {
				//     console.log(element);
				//     navigation.navigate(route, {
				//       item: element.id
				//     })
				//   }}
			>
				<Body>
					<Text>
						{element.name} {renderKind(element.kind)}
					</Text>
					<Text>Solicitante: {element.requested_by?.full_name}</Text>
					<Text>
						Monto solicitado:
						<Text style={{ fontWeight: "bold", color: "green" }}>
							{numeral(element.budget_requested).format("000,000.00")}
						</Text>
					</Text>
					<Text>Descripción: {element.description}</Text>
					{renderStatus(element.status, element)}
					{renderLastAprove(element)}
					{renderNextAprove(element)}
				</Body>
			</ListItem>
		);
	};

	if (working) {
		return <PreLoader />;
	}

	return (
		<Container style={styles.container}>
			<SafeAreaView style={styles.content}>
				<FlatList
					data={dataList}
					renderItem={({ item }) => renderListItem(item)}
					ListEmptyComponent={
						<ListItem>
							<Body>
								<Text>Sin elementos para mostrar</Text>
							</Body>
						</ListItem>
					}
					keyExtractor={(item) => item.id}
				/>
			</SafeAreaView>
		</Container>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: '#fff',
	},
	content: {
		flex: 1,
		padding: 10,
		// flexDirection: 'row',
		...(Platform.OS === "web" && {
			// maxWidth: '70%',
			width: "50%",
			alignSelf: "center",
		}),
	},
});
